import { type ComponentType } from 'react';

import loadable, { type LoadableComponent } from '@loadable/component';
import { type ParseKeys } from 'i18next';

const mainNavigationRoutes: { [key: string]: RouteEntry } = {
  information: {
    i18nKey: 'mainNavigation.entries.information',
    href: 'information',
    path: 'information',
    external: true,
  },
  organisationalInformation: {
    i18nKey: 'mainNavigation.entries.organisationalInformation',
    href: 'organisationalInformation',
    path: 'organisationalInformation',
    disabled: true, // if removed use the disable constant !
  },
  doubleMaterialityAssessment: {
    i18nKey: 'mainNavigation.entries.doubleMaterialityAssessment',
    href: 'double-materiality-assessment/topic-selection',
    path: 'double-materiality-assessment/topic-selection',
    external: true,
  },
  reporting: {
    i18nKey: 'mainNavigation.entries.reporting',
    href: 'reporting',
    path: 'reporting',
    freemiumDisabled: true,
  },
  surveys: {
    i18nKey: 'mainNavigation.entries.surveys',
    href: 'surveys',
    path: 'surveys',
    freemiumDisabled: true,
  },
};

const stakeholderNavigationRoutes: { [key: string]: RouteEntryWithComponent } = {
  information: {
    i18nKey: 'stakeholderProcess.information.title',
    href: 'information',
    path: 'information',
    Component: loadable(() => import('../pages/Stakeholder/StakeholderInformation')),
  },
  'topic-selection': {
    i18nKey: 'stakeholderProcess.topic-selection.title',
    href: 'topic-selection',
    path: 'topic-selection',
    disabled: true,
    Component: loadable(() => import('../pages/Stakeholder/StakeholderTopicSelections')),
  },
  'iro-definition': {
    i18nKey: 'stakeholderProcess.iro-definition.title',
    href: 'iro-definition',
    path: 'iro-definition',
    disabled: true,
    Component: loadable(() => import('../pages/Stakeholder/StakeholderIroDefinition')),
  },
  finalisation: {
    i18nKey: 'stakeholderProcess.finalisation.title',
    href: 'finalisation',
    path: 'finalisation',
    disabled: true,
    Component: loadable(() => import('../pages/Stakeholder/StakeholderFinalisation')),
  },
} as const;

const stakeholderAuthNavigationRoutes: { [key: string]: RouteEntryWithComponent } = {
  check: {
    i18nKey: 'auth.title',
    href: 'check',
    path: 'check',
    Component: loadable(() => import('../pages/StakeholderAuth/PageCheck')),
  },
} as const;

export type RouteEntry = {
  i18nKey: ParseKeys;
  href: string;
  path: string;
  disabled?: boolean;
  external?: boolean;
  freemiumDisabled?: boolean;
};

export type RouteEntryWithComponent = RouteEntry & {
  Component: LoadableComponent<unknown> | ComponentType<unknown>;
};

export default mainNavigationRoutes;
export { stakeholderNavigationRoutes, stakeholderAuthNavigationRoutes };
